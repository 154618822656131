.hm-c-share-bar {
    margin: 50px 0 20px 0;
}

.hm-c-share-bar__item {
    display: inline-block;
    position: relative;
    color: white;
    padding: 10px 5px;
    margin: 10px 25px;
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.2rem;
    @include transition(all 200ms ease-in-out);

    &:hover,:focus, {
        text-decoration: none; 
        color: white;
    }

    &:hover:before {
        background: lighten($blue, 8%);
    }
}

.hm-c-share-bar__item:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $blue;
    content: "";
    display: block;
    z-index: -1;
    transform: skew(-25deg);
    margin: 0 -20px;
    @include transition(all 200ms ease-in-out);

}