.hm-c-company-bar {
  position: relative;
  color: white;
  margin-top: 20px;
  margin-bottom: 40px;
}

.hm-c-company-bar__hr {
  width: 80px;
  margin-top: 20px;
  border-color: #ccc;
}

.hm-c-company-bar .col-12 {
  padding: 0;
}

.hm-c-companyimage {
  margin-top: 40px;
  margin-bottom: 60px;
  -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
}

.hm-c-highlight-bar {
    margin: 80px auto;
    position: relative;
    color: white;
    padding: 40px 15px;
}


.hm-c-highlight-bar:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: darken($blue, 5%);
    content: "";
    display: block;
    z-index: -1;
    margin: 0 -10px;
}

@include media-breakpoint-up(sm) {
    .hm-c-highlight-bar:before {
        margin: 0 -20px;
    }
}

@include media-breakpoint-up(xl) {
    .hm-c-highlight-bar:before {
        transform: skew(-25deg);
        margin: 0 -60px;
    }
}

@media screen and (min-width: 1400px) {
    .hm-c-highlight-bar {
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;
    }
}
.hm-c-highlight-bar h2,
.hm-c-company-bar h2 {

    text-transform: uppercase;
    text-align: center;
    font-size: 2rem;
}

.hm-c-highlight-bar h2 {
    margin-top: 15px;
    color: white;
}

.hm-c-highlight-bar h2 span,
.hm-c-company-bar h2 span {
    font-weight: 400;
    display: block;
}

.hm-c-highlight-bar__content {
    text-align: center;
}
@include media-breakpoint-up(md) {
.hm-c-highlight-bar__content {
    text-align: right;
}
}

.hm-c-highlight-bar__info {
    text-transform: uppercase;
    font-size: 1.125rem;

}

.hm-c-highlight-bar__info a {
    font-weight: 700;
    color: inherit;
    &:hover,:focus {
        text-decoration: none;
    }
}

.hm-c-highlight-bar__button {
    color: white;
    background: lighten($blue, 10%);
    position: absolute;
    right: 15px;
    bottom: -75px;
    padding: 7px 15px 7px 80px;
    text-align: right;
    display: block;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-weight: 600;
    @include transition(all 200ms ease-in-out);
    &:hover,
    :focus {
        color: white;
        text-decoration: none;
        padding-right: 20px;
        padding-left: 75px;
    }

}
