.hm-c-notice-bar__wrapper {
    background: $gray-200;
}

.hm-c-notice-bar {
    position: relative;
    margin: 40px auto;
    padding: 0 15px;
}

.hm-c-notice-bar h2 {
    margin-top: 15px;
    color: $blue;
}

.hm-c-notice-bar h2 span {
    font-weight: 400;
    display: block;
}

.hm-c-notice-bar__content {
    padding: 0 50px;
    text-align: center;
}
@include media-breakpoint-up(md) {
.hm-c-notice-bar__content {
    text-align: right;
}
}

.hm-c-notice-bar__info {
    text-transform: uppercase;
    font-size: 1.125rem;

}

.hm-c-notice-bar__info a {
    font-weight: 700;
    color: inherit;
    &:hover,:focus {
        text-decoration: none;
    }
}

.hm-c-notice-bar__button {    
    color: white;
    background: lighten($blue, 10%);
    padding: 7px 15px 7px 80px;
    text-align: right;
    display: inline-block;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-weight: 600;
    margin-top: 20px;
    @include transition(all 200ms ease-in-out);
    &:hover,
    :focus {
        color: white;
        text-decoration: none;
        padding-right: 20px;
        padding-left: 75px;
    }

}

.hm-c-notice-bar__img {
    padding: 0 50px;
}

.hm-c-icon-bar {
    font-size: 1.2rem;
    margin-bottom: -10px;
}

@include media-breakpoint-down(md) {
    .hm-c-notice-bar__content,
    .hm-c-notice-bar__img
     {
        padding: 40px 15px;
    }
    }