.hm-c-cert-gallery {
    margin: 50px auto;
}
.hm-c-cert-gallery__list {
    margin-top: 20px;
}
.hm-c-cert-gallery__item {
    display: block;
    position: relative;
    margin: 15px 0;
    outline: none;
    @include transition(all 200ms linear);
}

.hm-c-cert-gallery__item img {
    -webkit-box-shadow: 0 1px 6px 0 rgba(0,0,0,.1);
    box-shadow: 0 1px 6px 0 rgba(0,0,0,.1);
}

.hm-c-cert-gallery__item:hover {
    transform: scale(1.1);
    text-decoration: none;
}

.hm-c-cert-gallery__item:after {
    border-radius: 50%;
    background: url(../images/icons/check-circle-regular.svg) center center no-repeat;
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: -10px;
    right: -10px;
	@include transition(all 200ms ease-in-out);
}

.hm-c-cert-gallery__label {
    color: #8d8d8d;
    text-align: center;
    display: block;
    text-align: center;
    margin: 10px 0;
}

.hm-c-cert-gallery__label:hover {
    text-decoration: none;
}

.hm-c-zkfseals {
  margin-top: 50px;
  margin-bottom: 80px;
}
