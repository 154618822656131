.hm-c-contact-form__wrapper {
    margin-top: 50px;
    padding: 40px 0 80px 0;
    background-color: #efefef;
}

.hm-c-contact-form__submit {
    color: white;
    background: lighten($blue, 10%);
    padding: 7px 15px 7px 60px;
    margin-top: 11px;
    text-align: right;
    display: block;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-weight: 600;
    border: none;
    float: right;
    cursor: pointer;
    @include transition(all 200ms ease-in-out);
    &:hover,
    :focus {
        color: white;
        text-decoration: none;
        padding-right: 20px;
        padding-left: 55px;
    }

}

#InputMessage {
    height: 295px;
}

.hm-p-page #InputMessage {
    height: 210px;
}

.custom-control {
	margin: 11px 0;
}
.custom-control-label {
	font-size: 0.8rem;
	color: #6c757d;
	line-height: 1.2rem;
	margin-top: 2px;
}

.custom-control-label a {
	color: #464b50;
	text-decoration: underline;
}

.hm-c-privacy-check__revocation {
	display: block;
	margin-top: 10px;
}

.uniform__potty {
  position: absolute;
  left: -9999px;
}
