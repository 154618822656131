@import "footer";

//
// Scaffolding
// --------------------------------------------------
//
// Use this to add CSS rules according to the most generic html elements like
// `html`, `body` etc. and to override the content of
// node_modules/bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding.scss

// Fix viewport issues with IE 10.
// See http://getbootstrap.com/getting-started/#support-ie10-width
// stylelint-disable at-rule-empty-line-before
@-webkit-viewport { width: device-width; }
@-moz-viewport { width: device-width; }
@-ms-viewport { width: device-width; }
@-o-viewport { width: device-width; }
@viewport { width: device-width; }
// stylelint-enable


body {
	overflow-y: scroll;
	padding-top: 70px;

	// Optimize legibility on Retina displays
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
