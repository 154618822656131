.hm-c-footer__adress {
	background-color: $blue;
	color: white;
	padding: 40px 0 50px 0;

	p {
		margin: 0;
	}

	a {
		color: white;
		@include transition(200ms all ease-in-out);

		&:hover,:focus {
			color: darken(white, 20%);
			text-decoration: none;
		}
	}

}

.hm-c-footer__info {
	margin: 30px 0;
}

.hm-c-footer__icon {
	font-size: 3.2rem;
	text-align: center;
}

.hm-c-footer__meta {
	background-color: darken($blue, 5%);
	color: white;
	padding: 10px 0 15px 0;
	font-size: .8rem;

	a {
		color: white;
		@include transition(200ms all ease-in-out);

		&:hover,:focus {
			color: darken(white, 20%);
			text-decoration: none;
		}
	}
}

.hm-c-footer__info--mobile span,
.hm-c-footer__info--mobile .hm-c-footer__icon {
    color: #fb8638;
}
