/**
 * Set default positioning as a fallback for if the plugin fails
 */

 .background-video-wrapper {
	position: relative;
	overflow: hidden;
	background-position: center center;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
}

.background-video {
	position: absolute;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	top: 50%;
	left: 50%;
	-o-object-fit: cover;
	object-fit: cover;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}


/**
 * Fade in videos
 * Note the .js class - so non js users still
 * see the video
 */

.js .background-video {
	-webkit-transition: opacity 300ms linear;
	transition: opacity 300ms linear;
}

.js .background-video.is-visible {
	opacity: 1;
}

/**
 * Pause/play button
 */

.background-video-pauseplay {
	position: absolute;
	background: transparent !important;
	border: none !important;
	box-shadow: none !important;
	width: 20px;
	height: 20px;
	top: 15px;
	right: 15px;
	padding: 0 !important;
	cursor: pointer;
	outline: none !important;
}

.background-video-pauseplay span {
	display: none;
}

.background-video-pauseplay:after,
.background-video-pauseplay:before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}

.background-video-pauseplay.play:before {
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 15px solid #FFF;
}

.background-video-pauseplay.pause:before,
.background-video-pauseplay.pause:after {
	border-top: 10px solid #FFF;
	border-bottom: 10px solid #FFF;
	border-left: 5px solid #FFF;
}

.background-video-pauseplay.pause:after {
	left: 10px;
}