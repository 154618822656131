.hm-c-generic-page,
.hm-c-generic-container {
    margin: 60px 0 120px 0;
}

.hm-c-generic-page h1 {
    font-size: 1.875rem;
    line-height: 1.9rem;
    color: #222;
    margin-bottom: 20px;
}

.hm-c-generic-page h2,
.hm-c-generic-page h3 {
    margin-top: 20px;
}

.hm-c-generic-page img {
    margin: 20px 0;
}

.hm-c-module {
  margin-top: 60px;
  margin-bottom: 60px;
}
