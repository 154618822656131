//
// Demo elements
// --------------------------------------------------
// Just needed to style a few elements on the demo page calles demoElements.html

.theme-showcase {
	// Local variables
	//
	// Which are meant to be used only in this module. »Global« variables are stored
	// in /src/assets/scss/_variables.scss

	// Local mixins
	//
	// Which are meant to be used only in this module. »Global« mixins are stored
	// in /src/assets/scss/theme/_mixins.scss

	// Styles
	//
	.theme-dropdown .dropdown-menu {
		position: static;
		display: block;
		margin-bottom: 20px;
	}

	> p > .btn {
		margin: 5px 0;
	}

	.navbar .container {
		width: auto;
	}
}
