.hm-c-highlight-section {
    margin: 100px 0;
}



.hm-c-highlight-section__content {
    border-top: 6px solid $blue;
    position: relative;
    padding: 40px 15px 20px 15px;
    z-index: 10;
}

@include media-breakpoint-up(xl) {

    .hm-c-highlight-section__content {
        border-top: none;
    }

    .hm-c-highlight-section__content:before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url("../images/highlight-section-bg.svg");
        background-size: auto 101%;
        background-position: left top;
        background-repeat: no-repeat;
        content: "";
        display: block;
        z-index: -1;
        margin: 0 0 0 -290px;
    }

    .hm-c-highlight-section__content--left:before {
        background-image: url("../images/highlight-section-bg-left.svg");
        background-position: right top;
        margin: 0 -420px 0 0;
    }

    /*.hm-c-highlight-section__image img {
        max-width: none;
        height: 100%;
    }*/

}

.hm-c-highlight-quote {
    max-width: 500px;
}

.hm-c-highlight-section__image {
    padding: 0;
    text-align: center;
}

.hm-c-quote {
    font-size: 1.35rem;
    font-family: $headings-font-family;
    color: $blue;
    font-style: italic;
    text-align: center;
    width: 100%;
}

.hm-c-quote-image {
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.1);
}


/*figcaption {
    color: #333;
    line-height: 1.2rem;
    margin-top: 20px;
}

figcaption span {
    margin-left: 20px;
}

figcaption strong {
    font-size: 1.3rem;
    font-weight: 700;
    font-family:  $headings-font-family;
}*/

.hm-c-highlight-section__desc {
    max-width: 360px;
    text-align: center;
    margin: 0 30px;
}

.hm-c-highlight-section__desc h2 {
    text-transform: uppercase;
    color: $blue;
}
