

.hm-c-start-page {
    padding-top: 0;
}

.hm-c-header {
    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 95%,rgba(0,0,0,0) 98%);
    @include transition(all 200ms ease-in-out);
}

.hm-c-header--dark {
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
    background: #152f5e;
}

.hm-c-header--intro {
    padding-top: 30px;
}

.hm-c-header--intro.hm-c-header--dark {
    padding-top: 15px;
}

@include media-breakpoint-down(md) {

    .hm-c-header--intro {
        padding-top: 15px;
    }

    .hm-c-header {
        box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
        background: #152f5e;
    }

}

.navbar {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: .875rem;
}

.dropdown-item {
    font-size: .875rem;
    font-family: $headings-font-family;
    font-weight: 600;
    border-bottom: 1px solid #efefef;
}

.dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-nav {
    margin-top: 10px;
}
.navbar-nav .nav-link {
    padding: .5rem .135rem;
}
.social-icons {
    margin-top: 10px;
}

.navbar-brand img {
    width: 195px;
    height: 30px;
}

@include media-breakpoint-up(sm) {

    .navbar-brand img {
        width: 228px;
        height: 35px;
    }

}

@include media-breakpoint-up(lg) {

.nav-link {
    position: relative;
    &::before {
        transform: skew(-15deg);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: darken($blue, 5%);
        content: "";
        display: inline-block;
        z-index: -1;
        opacity: 0;
        @include transition(all 100ms ease-in-out);
    }

}

.nav-item:hover .nav-link,
.nav-item.active .nav-link {

    &::before {
        opacity: 1;
    }
}

.navbar-nav {
    margin-top: 0;
}
.navbar-nav .nav-link {
    padding: .5rem 0;
}
.social-icons {
    margin-top: 0;
}

}

.dropdown-toggle::after {
    display: none;
}

.social-icons {
    cursor: default;
}
.social-icons a {
    color: white;
    font-size: 18px;
    display: inline-block;
    margin: 0 5px;
    @include transition(all 200ms ease-in-out);
}

.social-icons a:hover {
    color: white;
    transform: scale(1.4);
}

.dropdown-item--fun {
    border-left: 4px solid $funrecreation;
}
.dropdown-item--safety {
    border-left: 4px solid $safetysecurity;
}
.dropdown-item--mobile {
    border-left: 4px solid $mobileservices;
}
.dropdown-item--local {
    border-left: 4px solid $localservices;
}





.scroll-top {
  position: fixed;
  display: block;
  bottom: 15px;
  right: 15px;
  z-index: 999;
  background: rgba(color("white"), 0.9);
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 1rem;
  box-shadow: 0 0 0px 5px rgba(0, 0, 0, .1);
  transition: .5s all ease;
  transform: translateY(40px);
  color: #8a8a8a;

  &.scroll-top-visible {
      transform: translateY(0);
  }

  &:hover {
      transform: translateY(rem(-5px));
  }
}
