h1 a,
h2 a,
h3 a {
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.hm-c-hero-header {
    height: 580px;
    background: url("../images/start/hero-image.jpg") center center;
    background-size: cover;
}

.hm-c-hero-header h1 {
    font-size: 2rem;
    text-align: center;
    text-transform: uppercase;
    color: white;
    text-shadow: 0px 2px 30px rgba(0, 0, 0, 1);
    margin-top: -100px;
    font-style: italic;
    position: relative;
    z-index: 5;
    line-height: 0.9;
    word-break: break-all;
}

@include media-breakpoint-up(sm) {
  .hm-c-hero-header h1 {
    word-break: keep-all;
    font-size: 3rem;
  }
}

@include media-breakpoint-up(lg) {
  .hm-c-hero-header h1 {
      font-size: 4.2rem;
      line-height: 0.9;
  }
}

.hm-c-category-intro {
    padding-bottom: 60px;
}

.hm-c-category-box__wrapper {
  margin-bottom: 30px;
}

.hm-c-category-box {
    display: block;
    background: white;
    margin-bottom: 30px;
    height: 100%;
    position: relative;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .2);
    @include transition(all 100ms ease-in-out);

    &:hover, :focus {
        text-decoration: none;
        transform: translateY(-10px)!important;
    }
}

.hm-c-category-box__wrapper:first-of-type() {
    margin-top: -153px;
}

@include media-breakpoint-up(sm) {
    .hm-c-category-box__wrapper:nth-of-type(-n+2) {
        margin-top: -153px;
    }
}

@include media-breakpoint-up(lg) {
    .hm-c-category-box__wrapper {
        margin-top: -153px;
    }
    .hm-c-category-intro {
        margin-top: 0;
    }
}

.hm-c-category-box__content {
    padding: 15px;
}

.hm-c-category-box__image {
  text-align: center;
}

.hm-c-category-box__content h2 {
    font-size: 1.125rem;
    line-height: 120%;
    border-bottom: 4px solid white;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: 12px;
    color: #222;
}

.hm-c-category-box__content p {
    font-size: 1rem;
    line-height: 120%;
    color: #787878;
}

.hm-c-category-box__button {
    padding: 7px 15px;
    text-align: right;
    display: block;
    color: white;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-weight: 600;
    position: absolute;
    bottom: 0px;
    width: 100%;
    @include transition(all 200ms ease-in-out);

}

@each $name, $value in $categorys {

    .hm-c-category-box--#{$name} h2 {
        border-bottom-color: $value;
    }

    .hm-c-category-box--#{$name} span {
        background-color: $value;
    }

    .hm-c-category-box--#{$name}:hover span {
        background-color: darken($value, 5%);
    }
}


.hm-c-category-intro {
    background: url("../images/start/pattern.jpg") center top no-repeat;
}

.hm-c-corporate-benefit {
    margin: 10px 0;
}

.hm-c-corporate-benefit__icon {
    width: 80px;
    height: 80px;
    font-size: 40px;
    color: white;
    background: $blue;
    line-height: 80px;
    border-radius: 80px;
    margin: 5px auto;
    text-align: center;
}

@include media-breakpoint-up(lg) {

.hm-c-corporate-benefit__icon {
    width: 120px;
    height: 120px;
    font-size: 50px;
    line-height: 120px;
    border-radius: 120px;
}

}
.hm-c-corporate-benefit__content {
    text-align: center;
    margin-top: 20px;
}
@include media-breakpoint-up(md) {
    .hm-c-corporate-benefit__content {
        margin-top: 0;
        text-align: left;
    }
    .hm-c-corporate-benefit {
        margin: 40px 0;
    }
}

.hm-c-corporate-benefit__content h2 {
    font-size: 1.5rem;
    color: #333;
}

.hm-c-headline-intro {
    text-align: center;
    padding: 20px 15px 30px 15px;
}

.hm-c-headline-intro h2 {
    color: $blue;
    text-transform: uppercase;
}

.hm-c-counter {
    margin-top: 40px;
    margin-bottom: 140px;
    text-align: center;
}

.hm-c-counter__count {
    font-size: 4rem;
    line-height: 80px;

    & h2 {
        font-size: 1.5rem;
        color: #333;
    }
}

.hm-c-counter__count .counter-timer {
    font-family: $headings-font-family;
    color: #152f5e;
    font-weight: 600;
}

.hm-c-counter i {
    font-size: 2.5rem;
    color: #152f5e;
    position: relative;
    display: inline-block;
    padding: 20px;
    line-height: 48px;
    height: 90px;
    width: 90px;

    &:before {
        border: 6px solid #152f5e;
        border-radius: 50%;
        display: block;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}
.hm-c-counter__counter-subline {
    font-size: 1rem;
    max-width: 400px;
    line-height: 1.2rem;
    margin: 0 auto;
}

.external-link {
  display: inline-block;
  word-wrap: none;
}
.external-link:after {
  background: url(../images/icons/external-link-solid.svg) no-repeat 0 0;
  background-size: 100%;
  content: "";
  display: inline-block;
  height: .8em;
  margin-bottom: -1px;
  margin-left: .4rem;
  width: .8em;
}
