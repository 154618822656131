/* The actual timeline (the vertical ruler) */

.hm-c-timeline {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0 0 0;
}

/* The actual timeline (the vertical ruler) */

.hm-c-timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    /*background: url(../images/icons/timeline-dots.svg) repeat-y;*/
    background-color: $blue;
    /*background-size: 6px 12px;*/
    top: 0;
    bottom: 0;
    left: 50%;
    opacity: .4;
}

@include media-breakpoint-up(sm) {
    .hm-c-timeline::after {
        margin-left: -18px;
    }
}

/* The actual timeline (the vertical ruler) */

.hm-c-timeline::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50px;
    top: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 5;
}

/* Container around content */

.hm-c-timeline__entry {
    padding: 10px 60px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */

.hm-c-timeline__entry::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    right: -15px;
    background-color: $blue;
    /*border: 4px solid $blue;*/
    top: 35px;
    border-radius: 50%;
    z-index: 1;
    /*box-shadow: 0 0 0 10px rgba(255, 255, 255, 1);*/
}

/* Place the container to the left */

.hm-c-timeline__entry--left {
    left: 0;
    text-align: right;
}

/* Place the container to the right */

.hm-c-timeline__entry--right {
    left: 50%;
}

.hm-c-timeline__entry--left .hm-c-timeline__content {
    float: right;
    max-width: 400px;
}

.hm-c-timeline__entry--right .hm-c-timeline__content {
    float: left;
    max-width: 400px;
}

/* Fix the circle for containers on the right side */

.hm-c-timeline__entry--right::after {
    left: -15px;
}

.hm-c-timeline__entry h3 {
    color: $blue;
    position: relative;
    padding-bottom: 5px;
    font-size: 1.8rem;
}

.hm-c-timeline__entry h3:before {
    position: absolute;
    display: block;
    content: "";
    background: rgb(214, 214, 214);
    width: 100%;
    height: 2px;
    bottom: -3px;
}

.hm-c-timeline__entry--left h3:before {
    right: -30px;
}

.hm-c-timeline__entry--right h3:before {
    left: -30px;
}

.hm-c-timeline__content p {
    margin-top: 10px;
}

.hm-c-timeline__entry {
    padding-left: 70px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */

@media screen and (max-width: 600px) {
    /* Place the timelime to the left */
    .hm-c-timeline::after {
        left: 31px;
    }
    /* Full-width containers */
    .hm-c-timeline__entry {
        width: 100%;
        padding-left: 100px;
        padding-right: 25px;
    }
    /* Make sure that all arrows are pointing leftwards */
    .hm-c-timeline__entry::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }
    /* Make sure all circles are at the same spot */
    .hm-c-timeline__entry--left::after,
    .hm-c-timeline__entry--right::after {
        left: 33px;
    }

    /* Make all right containers behave like the left ones */
    .hm-c-timeline__entry--right {
        left: 0%;
    }

    .hm-c-timeline__entry--left h3:before {
        right: 0;
    }
    
    .hm-c-timeline__entry--right h3:before {
        left: 0;
    }

    .hm-c-timeline__entry--left {
        text-align: left;
    }
    
    .hm-c-timeline__entry--left .hm-c-timeline__content,
    .hm-c-timeline__entry--right .hm-c-timeline__content {
        float: none;
    }
    
}

.hm-c-history {
    margin-top: 100px;
}

