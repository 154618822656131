.hm-c-fastnav__link {
    display: block;
    height: 210px;
    background-size: 100%;
    margin: 15px 0;
    position: relative;
    background-position: center center;
    overflow: hidden;
    @include transition(all 200ms ease-in-out);

    &:hover, :focus {
        background-size: 110%;
        text-decoration: none;
        span {
            text-decoration: none;
            opacity: .7;
        }
    }
}

.hm-c-fastnav__link:before {
    display: block;
    position: absolute;
    content: "";
    z-index: 1;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
}

.hm-c-fastnav__link span {
    color: white; 
    font-family: $headings-font-family;
    text-transform: uppercase;
    display: block;
    z-index: 4;
    position: relative;
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 20px;
    hyphens: auto;
    word-break: break-all;
    @include transition(all 200ms ease-in-out);

}
.hm-c-fastnav {
    margin: 60px auto;
}

.hm-c-desc-list {
    margin: 80px auto;
}

.hm-c-desc-list__item {
    margin: 30px 0;
}

.hm-c-desc-list__item img {
    margin-bottom: 15px;
}

.hm-c-desc-list__content h3 {
    font-size: 1.5rem;
    font-weight: 700;
    font-family:  $headings-font-family;
    border-bottom: 4px solid white;
    padding-bottom: 15px;
    margin-top: 10px;
}

@include media-breakpoint-down(sm) { 
.hm-c-desc-list__content h3 {
    text-align: center;
}
}
.hm-c-desc-list__content p {
    color: #787878;
    margin-top: 20px;
}

@each $name, $value in $categorys {

    .hm-p-page--#{$name} .hm-c-desc-list__content h3 {
        border-bottom-color: $value;
    }
}

.hm-c-desc-list__button {    
    color: $blue;
    text-align: right;
    display: block;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 15px;
    @include transition(all 200ms ease-in-out);

    &:hover,
    :focus {
        color: $blue;
        text-decoration: none;
        padding-right: 5px;
    }

}