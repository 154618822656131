.hm-c-topline {
    font-size: 50%;
}

.hm-c-news-list {
    margin-top: 100px;
    margin-bottom: 140px;
}

.hm-c-news-teaser {
    margin: 50px 0;
}

.hm-c-news-teaser__date {
    color: $blue;
    font-weight: 700;
}

.hm-c-news-teaser h2 {
    font-size: 1.5rem;
    font-weight: 700;
    font-family:  $headings-font-family;
    border-bottom: 4px solid white;
    padding-bottom: 15px;
    margin-top: 10px;
}

@each $name, $value in $categorys {

  .hm-c-news-teaser--#{$name} h2 {
    border-bottom-color: $value;
  }
}

.hm-c-news-teaser p {
    color: #787878;
    margin-top: 20px;
}

.hm-c-news-teaser__button {
    color: $blue;
    text-align: right;
    display: block;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-family: $headings-font-family;
    font-weight: 600;
    margin-top: 10px;
    @include transition(all 200ms ease-in-out);
    &:hover,
    :focus {
        color: $blue;
        text-decoration: none;
        padding-right: 5px;
    }

}

@include media-breakpoint-down(sm) {
    .hm-c-news-teaser__image {
        display: block;
        margin: 20px auto;
    }

    .hm-c-news-teaser__date, .hm-c-news-teaser h2 {
        text-align: center;
    }
}

.hm-c-news-article {
    margin: 60px 0 120px 0;
}

.hm-c-news-article p {
    text-align: justify;
}

.hm-c-news-article h1 {
    font-size: 1.875rem;
    line-height: 1.9rem;
    color: #222;
    margin-bottom: 20px;
    text-align: center;
}

.hm-c-news-article__date {
    color: $blue;
    font-weight: 700;
    margin: 20px 0 10px 0;
    text-align: center;
}

.hm-c-news-article__image {
    margin: 10px 0 40px 0;
}

.hm-c-pagination__divider {
  margin-top: 120px;
  margin-bottom: 10px;
}

.hm-c-pagination__btn {
  margin-top: 20px;
  text-transform: uppercase;
}


