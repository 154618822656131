.hm-c-team {
  margin-top: 60px;
  margin-bottom: 80px;

  h2 {
    margin-bottom: 15px;
  }

  hr {
    height: 2px;
    width: 200px;
    margin: 0 auto;
    background: $gray-400;
    border: none;
  }
}

.hm-c-team__list {
  margin-top: 20px;
}

.hm-c-team__member {
  margin-top: 15px;
  margin-bottom: 15px;

  h3::after {
    width: 40px;
    height: 2px;
    background-color: $gray-400;
    content: "";
    display: block;
    margin-top: 8px;
    margin-left: 1px;
  }

  img {
    margin-bottom: 15px;
  }
}

.hm-c-team__info {
  padding-left: 0;
  // -webkit-flex: 0 0 80%;
  // -ms-flex: 0 0 80%;
  // flex: 0 0 80%;
  // max-width: 80%;
}
.hm-c-team__icon {
  // -webkit-flex: 0 0 14%;
  // -ms-flex: 0 0 14%;
  // flex: 0 0 14%;
  // max-width: 14%;
  // padding-left: 15px;
 }
