/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
         url('../fonts/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v12-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/montserrat-v12-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Italic'), local('Montserrat-Italic'),
         url('../fonts/montserrat-v12-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v12-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v12-latin-italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v12-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v12-latin-italic.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-600 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/montserrat-v12-latin-600.eot'); /* IE9 Compat Modes */
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
         url('../fonts/montserrat-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v12-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v12-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-700 - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat-v12-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Bold'), local('Montserrat-Bold'),
         url('../fonts/montserrat-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v12-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v12-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
  }
  /* montserrat-700italic - latin */
  @font-face {
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/montserrat-v12-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
         url('../fonts/montserrat-v12-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/montserrat-v12-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/montserrat-v12-latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/montserrat-v12-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/montserrat-v12-latin-700italic.svg#Montserrat') format('svg'); /* Legacy iOS */
  }