.hm-c-section-header {
    height: 320px;
    background-color: #222;
    background-size: cover;
    background-position: center center;
}

.hm-c-section-header h1,
.hm-c-section-header h2 {
    font-size: 4rem;
    text-align: center;
    text-transform: uppercase;
    color: white;
    text-shadow: 0px 2px 20px rgba(0, 0, 0, 1);
    line-height: 3.6rem;
    margin-top: -10px;
}

.hm-c-product-stage h1 {
    font-size: 3rem;
    text-align: center;
    text-transform: uppercase;
    line-height: 2.4rem;
    margin-top: 25px;
    hyphens: auto;
    word-break: break-all;
}

@include media-breakpoint-down(md) {
    .hm-c-section-header h1,
    .hm-c-section-header h2 {
        font-size: 2.5rem;
        line-height: 2.3rem;
        word-break: break-all;
    }

    .hm-c-section-header {
        height: 260px;
    }

    .hm-c-product-stage h1 {
      font-size: 2rem;
      line-height: 2rem;
    }
}

.hm-c-section-header__cat {
    font-size: 1.875rem;
    font-weight: 600;
}

.hm-c-product-stage .hm-c-section-header__cat {
    font-size: 1.2rem;
    font-weight: 600;
}

.hm-c-category-divider {
    height: 7px;
    width: 100%;
    margin: 0;
    background-color: $blue;
}

@each $name, $value in $categorys {

    .hm-p-page--#{$name} .hm-c-category-divider {
        background-color: $value;
    }
}

@each $name, $value in $categorys {

    .hm-u-color--#{$name} {
        color: darken($value, 5%);
    }
    .hm-u-bg--#{$name} {
        background-color: $value;
    }
}

.hm-c-product-stage {
    background-color: #efefef;
    border-top: 1px solid white;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 40px;
    position: relative;
}

.hm-c-product-description {
    padding-top: 70px;
    padding-bottom: 00px;
}

.hm-c-product-description p {
    color: #787878;
}
.hm-c-product-description ul {
    list-style: none;
}

.hm-c-product-description li::before {
    content: "•";
    color: $blue;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    transform: scale(1.8);
}

.hm-c-product-description li {
    margin: 10px 0;
    color: #787878;
}

.hm-c-product-description + .hm-c-footer {
  margin-top: 100px;
}

@each $name, $value in $categorys {
    .hm-p-page--#{$name} .hm-c-product-description li::before {
        color: $value;
    }
}

.hm-c-document-link {
    text-align: center;
}

.hm-c-document-btn {
    display: inline-block;
    position: relative;
    color: white;
    padding: 15px 15px;
    margin: 60px 0 40px 0;
    text-transform: uppercase;
    font-family: $headings-font-family;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.4rem;
    @include transition(all 200ms ease-in-out);

    &:hover,:focus {
        text-decoration: none;
        color: white;
    }
}

.hm-c-document-btn strong {
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
}

.hm-c-document-btn span {
    display: block;
}

.hm-c-document-btn i {
    font-size: 2.7rem;
    margin-right: 15px;
}

.hm-c-document-btn:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: darken($blue, 5%);
    content: "";
    display: block;
    z-index: -1;
    @include transition(all 200ms ease-in-out);
}


@each $name, $value in $categorys {
    .hm-p-page--#{$name} .hm-c-document-btn:before {
        background-color: $value;
    }

    .hm-p-page--#{$name} .hm-c-document-btn:hover:before {
        background-color: darken($value, 4%);
    }

    .hm-p-page--#{$name} .hm-c-document-btn:hover {

    }
}

@include media-breakpoint-up(md) {
    .hm-c-document-btn:before {
        transform: skew(-25deg);
        margin: 0 -20px;
    }
}

.hm-c-product-gallery {
    margin: 50px auto 70px auto;
}
.hm-c-product-gallery__item {
    display: block;
    border-radius: 50%;
    position: relative;
    margin: 15px 0;
    outline: none;
    @include transition(all 200ms linear);
}

.hm-c-product-gallery__item:hover {
    transform: scale(1.1);
}

.hm-c-product-gallery__image {
    border-radius: 50%;
    border: 10px solid transparent;
}

.hm-c-product-gallery__item:before {
	border: 3px solid #e8e8e8;
	border-radius: 50%;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	@include transition(all 200ms ease-in-out);
}

.hm-c-product-gallery__item:after {
    border-radius: 50%;
    background: $blue url(../images/icons/plus-light.svg) center center no-repeat;
    background-size: 20px 20px;
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 10px;
    right: 10px;
	@include transition(all 200ms ease-in-out);
}

.hm-c-product-gallery__item:hover:before {
	border-color: $blue;
}

.hm-c-product-gallery__item:hover:after {
	background-color: $blue;
}

@each $name, $value in $categorys {
    .hm-p-page--#{$name} .hm-c-product-gallery__item:hover:before {
        border-color: $value;
    }

    .hm-p-page--#{$name} .hm-c-product-gallery__item:hover:after {
        background-color: $value;
    }
}

.hm-c-richtext {
    margin-top: 60px;
    margin-bottom: 60px;
}

.hm-c-richtext h2 {
    color: $blue;
    max-width: 500px;
    margin: 30px auto 20px auto;
}

.hm-c-notice-illu {
    font-size: .8rem;
    color: #b5b5b5;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

@include media-breakpoint-down(md) {
    .hm-c-notice-illu {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 10px;
        right: auto;
    }
}
